
import {
    RECEIVE_COLLECTIONS,
    LOG_DEVICE_ERROR,
    CLEAR_COLLECTIONS
} from "../constants";
import { CollectionState } from '../interfaces/states';
import { collectionActions } from "../actions/collectionActions";

const initialState: CollectionState = {
    collections: [],
    isFetching: false,
    error: null,
};


export default (state = initialState, action: collectionActions): CollectionState => {
    switch (action.type) {
        case RECEIVE_COLLECTIONS: {
            return { ...state, collections: [...state?.collections, ...action?.collection] };
        }
        case CLEAR_COLLECTIONS: {
            return initialState;
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        default: {
            return state;
        }

    }

}