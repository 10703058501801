import BreakdownReport from '../components/home/Assets/Report/BreakdownReport';



export enum AppRoutes {
    Home = "/home",
    Account = "/account",
    Login = "/auth/signin",
    SignUp = "/auth/signup",
}

export enum HomeRoutes {  
    Devices = AppRoutes.Home + "/devices",
    AddAndEditDevice = AppRoutes.Home + "/add-device",
    // Login = AppRoutes.Auth + "/signin",
    // SignUp = AppRoutes.Auth + "/signup",
    Maintanance = AppRoutes.Home + "/maintanance",
    Servicing = AppRoutes.Home + "/servicing",
    EditService = AppRoutes.Home + "/edit-service/:id",
    CreateService = AppRoutes.Home + "/create-service",
    ServicingDetails = AppRoutes.Home + "/service-details/:id",
    DeviceItem = AppRoutes.Home + "/device-data",
    Dashboard = AppRoutes.Home + "/dashboard",
    DashboardItem = AppRoutes.Home + "/dashboard/:id", 
    Groups = AppRoutes.Home + "/user-group", 
    ItemGroup = AppRoutes.Home + "/item-group",
    Schedule = AppRoutes.Home + "/report-schedule",
    CreateSchedule = AppRoutes.Home + "/create-schedule",
    UserProfile = AppRoutes.Home + "/user-profile",
    Users = AppRoutes.Home + "/users",
    Actions = AppRoutes.Home + "/Actions",
    Rules = AppRoutes.Home + "/Rules",
    Triggers = AppRoutes.Home + "/triggers",
    settings = AppRoutes.Home + "/settings",
    dataCollection = AppRoutes.Home + "/data",
    Report = AppRoutes.Home + "/reports",
    Events = AppRoutes.Home + "/events",
    EventActions = AppRoutes.Home + "/event-actions/:eventId",
    Viewbats = AppRoutes.Home + "/view-bats",
    AssetManagement = AppRoutes.Home + "/assets",
    AssetConfiguration = AppRoutes.Home + "/asset-configuration",
    ServiceReport = AppRoutes.Home + "/service-report",
    BreakdownReport = AppRoutes.Home + "/breakdown-report",
    Breakdown = AppRoutes.Home + "/breakdown",
    BreakdownDetails = AppRoutes.Home + "/breakdownDetails/:breakdownId",
    ViewAsset = AppRoutes.Home + "/view-assets",
    BatAdmin = AppRoutes.Home + "/admin-statistics",
    BatDashboard = AppRoutes.Home + "/bats",
    ViewRoomData = AppRoutes.Home + "/view-room/:roomId",
    SvgDashboard = AppRoutes.Home + "/view-svg-dashboard",
    ViewMap = AppRoutes.Home + "/view-map",
    Notification = AppRoutes.Home + "/view-notifications",
    ViewNotification = AppRoutes.Home + "/view-notification",
    BatsReport = AppRoutes.Home + "/audit-report",
}

export enum TriggerRoutes {
    EditTrigger = HomeRoutes.Triggers + "/:triggerId",
    CreateTrigger = HomeRoutes.Triggers + "/create"
}

export enum GroupRoutes {
    ViewGroupdetails = HomeRoutes.Groups + "/:groupId"
}

export enum ViewDeviceRoutes {
    ViewDevice = HomeRoutes.Devices + "/:deviceId",
}

export enum ViewEventActionRoutes {
    ViewEventActions = HomeRoutes.EventActions,
}

export enum ViewDeviceItemRoutes {
    ViewDeviceItem = HomeRoutes.DeviceItem + "/:deviceId",
}

export enum ViewCollectionRoutes {
    ViewCollection = HomeRoutes.dataCollection + "/:collectionId",
}

export enum ViewBatsRoutes {
    Viewbats = HomeRoutes.Viewbats,
    BatDashboard = HomeRoutes.BatDashboard,
    BatsReport = HomeRoutes.BatsReport,
    ViewRoomData = HomeRoutes.ViewRoomData,
    Report = HomeRoutes.Report,
    BatAdmin = HomeRoutes.BatAdmin,
    SvgDashboard = HomeRoutes.SvgDashboard,
    Notification = HomeRoutes.Notification,
    ViewNotification = HomeRoutes.ViewNotification + "/:notificationId"
}

export enum AssetsRoutes { 
    AssetManagement = HomeRoutes.AssetManagement, 
    AssetConfiguration = HomeRoutes.AssetConfiguration,
    ServiceReport = HomeRoutes.ServiceReport,
    BreakdownReport = HomeRoutes.BreakdownReport,
    Breakdown = HomeRoutes.Breakdown, 
    BreakdownDetails = HomeRoutes.BreakdownDetails,
    ServicingDetails = HomeRoutes.ServicingDetails,
    Servicing = HomeRoutes.Servicing,
    Maintanance = HomeRoutes.Maintanance,
    EditService =  HomeRoutes.EditService,
    CreateService = HomeRoutes.CreateService,
    ViewAsset = HomeRoutes.ViewAsset + "/:assetId",
}