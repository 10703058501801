
import {
    RECIEVE_DATAVALUE_SIZE,
    RECIEVE_EVENT_SIZE,
    LOG_DEVICE_ERROR,
    RECIEVE_ASSET_SIZE,
    RECIEVE_TRIGGER_SIZE,
    VERSION
} from "../constants";
import { PageSizeState } from '../interfaces/states';
import { PageSizeAction } from "../actions/pageSizeAction";

const initialState: PageSizeState = {
    eventSize: 10,
    dataValueSize: 10,
    assetLandingPageRowSize: 10,
    triggerLandingPageRowSize:10,
    isFetching: false,
    version:'',
    error: null,
};


export default (state = initialState, action: PageSizeAction): PageSizeState => {
    switch (action.type) {
        case RECIEVE_EVENT_SIZE: {
            return {...state, eventSize: action?.eventSize };
        }

        case RECIEVE_DATAVALUE_SIZE: {
            return {...state, dataValueSize: action?.dataValueSize };
        }

        case RECIEVE_ASSET_SIZE: {
            return {...state, assetLandingPageRowSize: action?.assetSize };
        }
        case RECIEVE_TRIGGER_SIZE: {
            return {...state, triggerLandingPageRowSize: action?.triggerSize };
        }

        case VERSION: {
            return {...state, version: action?.version };
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        default: {
            return state;
        }

    }

}