import {
    RECEIVE_COLLECTION_ITEMS,
    LOG_DEVICE_ERROR,
    CLEAR_COLLECTION_ITEMS
} from "../constants";
import { CollectionItemState } from '../interfaces/states';
import { collectionItemActions } from "../actions/collectionItemActions";

const initialState: CollectionItemState = {
    collectionItem: [],
    isFetching: false,
    error: null,
};


export default (state = initialState, action: collectionItemActions): CollectionItemState => {
    switch (action.type) {
        case RECEIVE_COLLECTION_ITEMS: {
            return { ...state, collectionItem: [...state?.collectionItem, ...action?.collectionItem] };
        }
        case CLEAR_COLLECTION_ITEMS: {
            return initialState;
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        default: {
            return state;
        }

    }

}