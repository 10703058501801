import * as constants from '../constants';
import { PageSizeActions } from '../interfaces/actions';
import { AxiosError } from 'axios';

export function revieveEventPageSize(eventSize: number): PageSizeActions {
    return {
        type: constants.RECIEVE_EVENT_SIZE,
        eventSize,
    }
}

export function revieveDataValuePageSize(dataValueSize: number): PageSizeActions {
    return {
        type: constants.RECIEVE_DATAVALUE_SIZE,
        dataValueSize,
    }
}

export function recieveAssetDataPageSize(assetSize: number): PageSizeActions {
    return {
        type: constants.RECIEVE_ASSET_SIZE,
        assetSize,
    }
} 

export function recieveTriggerDataPageSize(triggerSize: number): PageSizeActions {
    return {
        type: constants.RECIEVE_TRIGGER_SIZE,
        triggerSize,
    }
}

export function version(version: string): PageSizeActions {
    return {
        type: constants.VERSION,
        version,
    }
}



export function reset(): PageSizeActions {
    return {
        type: constants.RESET
    }
}



export function logGroupsError(error: AxiosError): PageSizeActions {
    return {
        type: constants.LOG_GROUP_ERROR,
        error,
    }
}


export type PageSizeAction = PageSizeActions;