
import {
    RECEIVE_ITEMS,
    LOG_DEVICE_ERROR,
    CLEAR_ITEMS
} from "../constants";
import { DashboardItemState } from '../interfaces/states';
import { dashboardItemsActions } from "../actions/dashboardItemActions";

const initialState: DashboardItemState = {
    dashItems: [],
    isFetching: false,
    error: null,
};


export default (state = initialState, action: dashboardItemsActions): DashboardItemState => {
    switch (action.type) {
        case RECEIVE_ITEMS: {
            return { ...state, dashItems: [...state?.dashItems, ...action?.items] };
        }
        case CLEAR_ITEMS: {
            return initialState;
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        default: {
            return state;
        }

    }

}