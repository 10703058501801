
import {
    RECEIVE_DEVICE_IDENTITY,
    LOG_DEVICE_ERROR,
    CLEAR_DEVICE_IDENTITY
} from "../constants";
import { DeviceIdentityState } from '../interfaces/states';
import { deviceIdentityActions } from "../actions/deviceIdentityActions";

const initialState: DeviceIdentityState = {
    deviceIdentities: [],
    isFetching: false,
    error: null,
};


export default (state = initialState, action: deviceIdentityActions): DeviceIdentityState => {
    switch (action.type) {
        case RECEIVE_DEVICE_IDENTITY: {
            return { ...state, deviceIdentities: [...state?.deviceIdentities, ...action?.deviceIdentity] };
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        case CLEAR_DEVICE_IDENTITY: {
            return initialState;
        }
        default: {
            return state;
        }

    }

}