
import {
    RECEIVE_EVENT_ITEMS,
    RECEIVE_EVENT_TRIGGER,
    LOG_DEVICE_ERROR,
    RESET
} from "../constants";
import { EventItemState } from '../interfaces/states';
import { eventItemActions } from "../actions/eventItemActions";

const initialState: EventItemState = {
    eventItems: [],
    eventTriggers:[],
    isFetching: false,
    error: null,
};


export default (state = initialState, action: eventItemActions): EventItemState => {
    switch (action.type) {
        case RECEIVE_EVENT_ITEMS: {
            return { ...state, eventItems: [...state?.eventItems, ...action?.eventItem] };
        }
        case RESET: {
            return  { ...state, eventTriggers: [state?.eventTriggers, ...action?.eventTrigger] };
        }
         case RECEIVE_EVENT_TRIGGER: {
            return { ...state, eventTriggers: [state?.eventTriggers, action?.eventTrigger] };
        }
        case LOG_DEVICE_ERROR: {
            return { ...state, error: { message: action.error?.message, type: action?.error?.response?.statusText } };
        }
        default: {
            return state;
        }

    }

}