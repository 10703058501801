import  React, { useEffect } from "react";
import { Loader } from "@sebgroup/react-components/dist/Loader";
import { AppRoute } from "../utils/functions";
import { Redirect, Switch, RouteComponentProps, withRouter } from "react-router";

import { AuthState, States } from '../interfaces/states';
import { useSelector } from 'react-redux';
import { store } from "../store/configureStore";
import { receiveAuth, authActions } from '../actions/authActions';



import { actionTypes } from "../types";
import NotificationHook from "./shared/NotificationHook";
import { AppRoutes, HomeRoutes } from '../enums/routes';
import Signup from './account/signupForm/Signup';
import CheckApiValue from "./shared/version";
import { version } from "../actions/pageSizeAction";
import axios from "axios";


const NotFound: React.LazyExoticComponent<React.FunctionComponent<RouteComponentProps>> = React.lazy(() => import("./notFound/404"));
const Home: any = React.lazy(() => import("./home/Home"));
const Account: any = React.lazy(() => import("./account/Account"));
const Auth: any = React.lazy(() => import("./account/signinForm/Signin"));
const SignUp: any = React.lazy(() => import("./account/signupForm/Signup"));
//const Settings: any = React.lazy(() => import("./settings/index"));


export interface SharedProps extends RouteComponentProps {
  actions: actionTypes;
}

const App: React.FunctionComponent<SharedProps> = React.memo((props: SharedProps): React.ReactElement<void> => {
  const authState: AuthState = useSelector((states: States) => states?.auth);
  const authStatee = useSelector((states: States) => states?.pageSize);

 


const [value, setValue] = React.useState<any>(null);

const checkApi = async () => {
  try {
    const response = await axios.get('https://api.prod.senshost.com/frontend/version');
    const newValue = response.data;
    setValue(newValue);
    if(!authStatee?.version){
      store.dispatch(version(''));
  }
    
    if (newValue !== authStatee?.version) {
      store.dispatch(version(newValue));
      if (window.confirm('New version available. The page will refresh.')) {
        // Clear all caches
          caches.keys().then((names) => {
            Promise.all(names.map(name => caches.delete(name))).then(() => {
              // Perform a hard reload
              window.location.reload();
            });
          });
      }
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
  }
};

useEffect(() => {
  const intervalId = setInterval(checkApi, 60000); // 300000ms = 5 minutes

  return () => clearInterval(intervalId); // Clear interval on component unmount
}, []);







  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.addEventListener('message', (event) => {
  //       console?.log(event?.data);
  //       if (event.data.type === 'CHECK_VERSION') {
  //         const apiVersion = event.data.apiVersion;
  //         if (apiVersion !== authStatee?.version) {
  //         store.dispatch(version(apiVersion));
          
  //           alert('New version available. The page will refresh.');
  //           caches.keys().then((cacheNames) => {
  //             return Promise.all(cacheNames.map((cache) => caches.delete(cache)));
  //           }).then(() => {
  //             window.location.reload();
  //           });
  //         }
  //       }
  //     });
  //   }
  // }, []);


  return (
    <div >
      <NotificationHook />
      <React.Suspense fallback={<Loader toggle={true} />}>
        <Switch>
          <Redirect exact from="/" to={AppRoutes.Home} />
          <AppRoute path={AppRoutes.Account} component={(Account)} props={props} />
          <AppRoute path={AppRoutes.Login} component={(Auth)} props={props} />
          <AppRoute path={AppRoutes.SignUp} component={(SignUp)} props={props} />
           {/* <AppRoute path={AppRoutes.Account} component={(Account)} props={props} /> */}
          <AppRoute path={AppRoutes.Home} component={(Home)} props={props} />
          <AppRoute path="*" component={NotFound} props={props} />
         
        </Switch>
      </React.Suspense>
    </div>
  );

});


export default withRouter<any, any>(App);