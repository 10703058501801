
import {
    SET_DATA_REFRESH_TIME,
} from "../constants";
import { SettingsState } from '../interfaces/states';
import { settingActions } from "../actions/settingActions";
import configs from "../configs"
export const initialState: SettingsState = {
    dashboardRefresh: (configs.dashbordRefreshRate * (1000 * 60)), // 5 seconds is default
};

export default (state = initialState, action: settingActions): SettingsState => {
    switch (action.type) {
        case SET_DATA_REFRESH_TIME: {
            return { ...state, dashboardRefresh: (action.dashboardRefresh * 1000 * 60)  };
        }
        default: {
            return state;
        }

    }

}